import React from "react"
// import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import SiniestrosLayout from "../../components/siniestros-layout"

import icoOrbis from "../../assets/old/img/logos-ase/ico-orbis.png";

export default () => (
    <SiniestrosLayout subCompaniaActivo="orbis">
        <Helmet>
            <title>Orbis Seguros - Siniestros</title>
            <meta
                name="description"
                content="Servicio de asistencia Orbis Seguros: 0800-555-7722 / 0810-666-4001."
            />
        </Helmet>

        <hr className="mb-4" />
        {/* Quotation */}
        <div className="card blog-card mb-2">
            <div className="card-body px-sm-5 py-5 bg-secondary">
                <img
                    className="float-md-right pb-2"
                    src={icoOrbis}
                    alt="Orbis Seguros"
                />
                <h4 className="lead post-title text-muted">Orbis Seguros</h4>
                <p className="">Servicio de asistencia: 0800-555-7722 / 0810-666-4001.</p>
            </div>
        </div>
        <p className="pt-5 pb-3">
            Tuvo un accidente de tránsito con Terceros Lesionados, comunicarse al 0800-555-7722 / 0810-666-4001. Estamos para ayudarte. Servicio de asistencia legal en caso de siniestro con lesiones, ayuda inmediata en el lugar del hecho y asistencia en trámites administrativos.
        </p>
        <p>
            Dentro de las 72 hs. Posteriores al siniestro deberá realizar la denuncia administrativa.
        </p>
        <h6>Siniestro por robo total</h6>
        <p>
            Si tiene productor, comunicarse con el mismo para dar aviso del siniestro. En caso de no tenerlo deberá presentarse personalmente en Av. Boedo 150 de Lunes a Viernes de 10 a 16 hs. para realizar la denuncia de robo con la siguiente documentación:
        </p>

        <hr className="pb-4" />



    </SiniestrosLayout>
)
