import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

import icoAllianz from "../assets/old/img/logos-ase/ico-allianz.png";
import icoExperta from "../assets/old/img/logos-ase/ico-experta.png"
import icoProvincia from "../assets/old/img/logos-ase/ico-provincia.png";
import icoRus from "../assets/old/img/logos-ase/ico-rus.png";
import icoSancor from "../assets/old/img/logos-ase/ico-sancor.png";
import icoCristobal from "../assets/old/img/logos-ase/ico-sancristobal.png";
import icoSura from "../assets/old/img/logos-ase/ico-galicia.png";
import icoMercantil from "../assets/old/img/logos-ase/ico-mercantilandina.png";
import icoParana from "../assets/old/img/logos-ase/parana-seguros.png";
import icoGaleno from "../assets/old/img/logos-ase/ico-galeno.png";
import icoBerkley from "../assets/old/img/logos-ase/ico-berkley.png"

export default ({ subCompaniaActivo, children }) => (
    <Layout menuActivo="siniestros">

        <section className="pb-5 pt-5" id="cases">

            <div className="container pb-5 mb-3">
                <div className="row">
                    {/* Post Content */}
                    <div className="col-lg-9">

                        {children}

                        {/* Entry Nanigation */}
                        <nav className="entry-navigation">
                            <div></div>
                            <Link className="back-btn" to="/siniestros"><i className="fe-icon-grid"></i></Link>
                            <div></div>
                        </nav>
                    </div>
                    <div className="col-lg-3">
                        {/* Single Post Sidebar */}
                        {/* Off-Canvas Container */}
                        <aside className="offcanvas-container" id="blog-single-sidebar">
                            <div className="offcanvas-scrollable-area px-4 pt-5 px-lg-0 pt-lg-0"><span
                                className="offcanvas-close"><i className="fe-icon-x"></i></span>
                                {/* Categories */}

                                <div className="widget widget-featured-posts">
                                    <h4 className="widget-title"> Otras Aseguradoras</h4>

                                    <div
                                    className={`${subCompaniaActivo === "allianz" && "d-none"}`}
                                    >
                                      <div className="featured-post-info py-4 px-2">
                                          <div className="featured-post-thumb">
                                              <Link className="featured-post" to="/siniestros/allianz">
                                                  <img
                                                      src={icoAllianz}
                                                      alt="Allianz Seguros"
                                                  />
                                              </Link>
                                          </div>
                                        </div>
                                      <hr />
                                    </div>

                                    <div
                                        className={`${subCompaniaActivo === "experta" && "d-none"}`}
                                    >
                                        <div className="featured-post-info py-4 px-2">
                                            <div className="featured-post-thumb">
                                                <Link className="featured-post" to="/siniestros/experta">
                                                    <img
                                                        src={icoExperta}
                                                        alt="Mapfre Seguros"
                                                    />
                                                </Link>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>

                                    <div
                                        className={`${subCompaniaActivo === "provincia" && "d-none"}`}
                                    >
                                        <div className="featured-post-info py-4 px-2">
                                            <div className="featured-post-thumb">
                                                <Link className="featured-post" to="/siniestros/provincia">
                                                    <img
                                                        src={icoProvincia}
                                                        alt="Provincia Seguros"
                                                    />
                                                </Link>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>

                                    <div
                                        className={`${subCompaniaActivo === "rus" && "d-none"}`}
                                    >
                                        <div className="featured-post-info py-4 px-2">
                                            <div className="featured-post-thumb">
                                                <Link className="featured-post" to="/siniestros/rus">
                                                    <img
                                                        src={icoRus}
                                                        alt="Rio Uruguay Seguros"
                                                    />
                                                </Link>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>

                                    <div
                                        className={`${subCompaniaActivo === "sancor" && "d-none"}`}
                                    >
                                        <div className="featured-post-info py-4 px-2">
                                            <div className="featured-post-thumb">
                                                <Link className="featured-post" to="/siniestros/sancor">
                                                    <img
                                                        src={icoSancor}
                                                        alt="Sancor Seguros"
                                                    />
                                                </Link>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>

                                    <div
                                        className={`${subCompaniaActivo === "san-cristobal" && "d-none"}`}
                                    >
                                        <div className="featured-post-info py-4 px-2">
                                            <div className="featured-post-thumb">
                                                <Link className="featured-post" to="/siniestros/san-cristobal">
                                                    <img
                                                        src={icoCristobal}
                                                        alt="San Cristobal Seguros"
                                                    />
                                                </Link>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>

                                    <div
                                        className={`${subCompaniaActivo === "sura" && "d-none"}`}
                                    >
                                        <div className="featured-post-info py-4 px-2">
                                            <div className="featured-post-thumb">
                                                <Link className="featured-post" to="/siniestros/galicia-seguros">
                                                    <img
                                                        src={icoSura}
                                                        alt="Galicia Seguros"
                                                    />
                                                </Link>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>

                                    <div
                                        className={`${subCompaniaActivo === "mercantil" && "d-none"}`}
                                    >
                                        <div className="featured-post-info py-4 px-2">
                                            <div className="featured-post-thumb">
                                                <Link className="featured-post" to="/siniestros/mercantil-andina">
                                                    <img
                                                        src={icoMercantil}
                                                        alt="Mercantil Andina"
                                                    />
                                                </Link>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>

                                    <div
                                        className={`${subCompaniaActivo === "parana" && "d-none"}`}
                                    >
                                        <div className="featured-post-info py-4 px-2">
                                            <div className="featured-post-thumb">
                                                <Link className="featured-post" to="/siniestros/parana">
                                                    <img
                                                        src={icoParana}
                                                        alt="Parana Seguros"
                                                    />
                                                </Link>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>

                                    <div
                                        className={`${subCompaniaActivo === "galeno" && "d-none"}`}
                                    >
                                        <div className="featured-post-info py-4 px-2">
                                            <div className="featured-post-thumb">
                                                <Link className="featured-post" to="/siniestros/galeno">
                                                    <img
                                                        src={icoGaleno}
                                                        alt="Galeno Seguros"
                                                    />
                                                </Link>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>

                                  <div
                                    className={`${subCompaniaActivo === "berkley" && "d-none"}`}
                                  >
                                    <div className="featured-post-info py-4 px-2">
                                      <div className="featured-post-thumb">
                                        <Link className="featured-post" to="/siniestros/berkley">
                                          <img
                                            src={icoBerkley}
                                            alt="Berkley Argentina Seguros"
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <hr />
                                  </div>

                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>

        </section>


    </Layout>
)
